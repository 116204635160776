// "use client";
import React from "react";
import styles from "./Footer.module.css";
import { multipleClassName } from "../../lib/utils/helpers/helper";
import CustomImageComponent from "../common/CustomImage/CustomImageComponent";

const LogoSection = ({
  title,
  content,
}: {
  title: string;
  content: React.ReactNode[];
}) => {
  return (
    <>
      <div className={styles.header}>{title}</div>
      <div className={styles.divider}></div>
      <div className={styles.contentContainerWithLogo}>
        {content.map((item: React.ReactNode) => {
          return item;
        })}
      </div>
    </>
  );
};

const LinkSection = ({
  title,
  content,
}: {
  title: string;
  content: any[];
}) => {
  return (
    <>
      <div className={styles.header}>{title}</div>
      <div className={styles.divider}></div>
      <div className={styles.contentContainer}>
        {content.map((item: any) => {
          return (
            <div style={{ display: "flex" }}>
              <a
                href={item.link}
                target={item.outside ? "_blank" : ""}
                className={styles.link}
              >
                {item.title}
              </a>
            </div>
          );
        })}
      </div>
    </>
  );
};

const Footer = (props) => {
const data = props;

  const logoSectionData = {
    socials: {
      content: (data.socials.content).map((object: any) => (
        <div
          style={{
            position: "relative",
            height: "clamp(36px,2.6vw,60px)",
            width: "clamp(36px,2.6vw,60px)",
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(object.link, "_blank")!.focus();
          }}
        >
          <CustomImageComponent
            src={object.img}
            alt={object.title}
            layout="fill"
            objectFit="contain"
          />
        </div>
      )),
    },
    member: {
      content: data.member.content.map((object: any) => (
        <div
          style={{
            position: "relative",
            height: "clamp(55px,2.6vw,60px)",
            width: "clamp(78px,4.47vw,86px)",
          }}
        >
          <CustomImageComponent src={object.img} alt={object.title} layout="fill" objectFit="contain" />
        </div>
      )),
    },
    payment: {
      content: data.payment.content.map(
          (object: { title: string; img: string }) => (
          <div
            style={{
              position: "relative",
              height: "clamp(20px,3.1vw,60px)",
              width: "clamp(45px,6.2vw,120px)",
            }}
          >
            <CustomImageComponent
              src={object.img}
              alt={object.title}
              layout="fill"
              objectFit="contain"
            />
          </div>
        )
      ),
    },
  };
  return (
    <div className={styles.root}>
      <div className={styles.winzoImageMobileConatiner}>
        <div className={styles.winzoImageMobile}>
          <CustomImageComponent
            src="https://d3g4wmezrjkwkg.cloudfront.net/website/images/ttt-revamp/ttt-logo-V2.webp"
            alt={"WINZO"}
            layout="fill"
            objectFit="contain"
          />
        </div>
      </div>
      <div
        className={multipleClassName(
          "container",
          styles.footerContentContainer
        )}
      >
        <div className={styles.leftPanel}>
          <div className={styles.winzoImage}>
            <CustomImageComponent
              src="https://d3g4wmezrjkwkg.cloudfront.net/website/images/ttt-revamp/ttt-logo-V2.webp"
              alt={"WINZO"}
              layout="fill"
              objectFit="contain"
            />
          </div>
          <div className={styles.socials}>
            <LogoSection
              title={data.socials.title}
              content={logoSectionData.socials.content}
            />
          </div>
          <div className={styles.linkSectionContainer}>
            {Object.keys(data.linkSection).map((key: string, index: number) => {
              return (
                <div>
                  <LinkSection
                    title={data.linkSection[key].title}
                    content={data.linkSection[key].content}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.rightPanel}>
          <div className={styles.socialsMobile}>
            <LogoSection
              title={data.socials.title}
              content={logoSectionData.socials.content}
            />
          </div>
          <div className={styles.paymentPartners}>
            <LogoSection
              title={data.payment.title}
              content={logoSectionData.payment.content}
            />
          </div>
        </div>
      </div>
      <div className={multipleClassName("container", styles.footNoteContainer)}>
        <div className={styles.dividerMobile}></div>
        <div className={styles.footNote}>
          <div>{`© ${new Date().getFullYear()}` + data.footNote}</div>
          &nbsp;
          {data?.footNoteLinks?.length > 0 && <div>|</div>}
          &nbsp;
          {data?.footNoteLinks?.map((footNoteLinkData: any, index: number) => (
            <>
              <a
                href={footNoteLinkData.link}
                target={footNoteLinkData.outside ? "_blank" : ""}
              >
                {footNoteLinkData.title}
              </a>
              {index !== data?.footNoteLinks?.length - 1 && (
                <span>&nbsp;-&nbsp;</span>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;

import { IGetStaticPageDataReturnProps } from "../../lib/types/Misc.type";
import {
  IOnlineGamesPageData,
} from "../../lib/types/world-war";
import React from "react";
import { ICommonData, ILanguageToggleData } from "../../lib/types/common";
import {
  isMobile,
} from "../../lib/utils/constants/PagesConstants";
import {
  GDC_HEADER_TYPE,
} from "../../lib/utils/constants/GlobalConstants";
import {
  IHeaderComponentProps,
  IPageFAQComponentProps,
} from "../../lib/types/components";
import HeaderComponentV2 from "../../components/common/Header/HeaderComponentV2";
import { LANGUAGE_COUNTRY } from "../../lib/utils/constants/LocaleAndCountryConstants";
import BannerComponent from "../../components/TheTechTriumph/BannerComponent";
import AboutUs from "../../components/TheTechTriumph/AboutUs";
import ThePrize from "../../components/TheTechTriumph/ThePrize";
import Eligibility from "../../components/TheTechTriumph/Eligibility";
import Evaluation from "../../components/TheTechTriumph/Evaluation";
import Timelines from "../../components/TheTechTriumph/Timelines";
import Jury from "../../components/TheTechTriumph/Jury";
import MakingTheLegacy from "../../components/BTTC3/MakingTheLegacy/MakingTheLegacyS3V2";
import OurPartners from "../../components/TheTechTriumph/OurPartners";
import Faq from "../../components/TheTechTriumph/Faq";
import Footer from "../../components/TheTechTriumph/Footer";

function TheTechTriumph(props: IGetStaticPageDataReturnProps<IOnlineGamesPageData>) {
  const locale = LANGUAGE_COUNTRY.EN_IN;
  const pageName = props.apiData.pageName;

  const content: any = props.apiData;
  const commonData: ICommonData = props.apiData.commonData as ICommonData;
  const languageToggleData: ILanguageToggleData =
    props.apiData?.languageToggleData!;
  const selectLanguageText = props.apiData.commonData.selectLanguageText
  if(commonData.text3){
    content.bannerHome.text3 = commonData.text3
  }
  if(commonData.text4){
      content.bannerHome.text4 = commonData.text4
  }

  const headerCompProps: IHeaderComponentProps = {
    specialData: content.specialData,
    fixedDownloadButtonData: commonData.fixedDownloadButton,
    languageToggleData: languageToggleData,
    locale: locale,
    countrySwitcherText: commonData.multiRegional?.countrySwitcherText,
    hideHamburger: true,
    showSubmitButton: content.showSubmitButton,
    gdcFormUrl: content.tttFormUrl,
    gdcButtonText: content.gdcButtonText,
    selectLanguageText,
    pageName,
  };

  const pageFAQCompProps: IPageFAQComponentProps = {
    pageFAQData: content.pageFAQ,
    showFaqSchema: false,
  };

  return (
    <>
      <main>
        <HeaderComponentV2
          {...headerCompProps}
          hideDownloadButton
          headerType={GDC_HEADER_TYPE}
        />
        <BannerComponent {...content.bannerDataV2}/>
        <AboutUs {...content.aboutUsData}/>
        <ThePrize {...content.prizeData}/>
        <Eligibility {...content.eligibilityData} />
        <Evaluation {...content.evaluationData} />
        <Timelines {...content.timelineDataV2} />
        {/* <Jury {...content.juryData} /> */}
        <MakingTheLegacy
          data={content.makingTheLegacyData}
          logos={content.gdcBanner.logos}
        />
        <OurPartners {...content.partnersData}/>
        <Faq {...pageFAQCompProps}/>
        <Footer {...content.footerData}/>
      </main>
    </>
  );
}

export default TheTechTriumph;
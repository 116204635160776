"use client"
import { useState } from "react";
import styles from "./MakingTheLegacyS3V2.module.css";
import { dynamicCssSize } from "../../../lib/utils/constants/PagesConstants";
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";
import MakingTheLegacySlider from "../IndiaPavilionSlider/IndianPavilionSlider";
import { IlogoImage } from "../../../lib/types/components";
import JuryOfTheContestComponent from "../JuryS3/JuryOfTheContestS3V2";
import ScrollingLogos from "../../common/GDC/ScrollingLogos";
import { multipleClassName } from "../../../lib/utils/helpers/helper";
import parse from 'html-react-parser';

export default function MakingTheLegacy(props) {
    const [tab, setTab] = useState(1);
    const data = props.data;
    const values = data.tabData;

    return (
      <>
        <div className={multipleClassName("container-fluid", styles.main)}>
          <div className={styles.wedgeTop} />
          <div className="container">
            <div className={styles.waterMarkAndTextContainer}>
              <div className={styles.waterMarkNoWrap}>{data.watermark}</div>
              <div className={styles.heading}>{data.title}</div>
              <div className={styles.subHeading}>{parse(data.subTitle)}</div>
            </div>
            <div className={styles.seasonTabs}>
              <div
                className={styles.tabWrapper}
                onClick={() => {
                  setTab(1);
                }}
                style={{
                  backgroundColor: tab === 1 ? "#e3425b" : "#fff",
                  clipPath: `polygon( 72% 0, 90% 100%, 0 100%, 0 0)`,
                  zIndex: tab === 1 ? 10 : 5,
                  paddingTop: tab === 0 ? 6 : 0,
                }}
              >
                <div
                  className={
                    1 === tab
                      ? multipleClassName(styles.tab, styles.selectedTab)
                      : styles.tab
                  }
                  style={{
                    clipPath:
                      1 === tab
                        ? `polygon(77% 0, 97% 100%, 0 100%, 0 0)`
                        : "none",
                  }}
                >
                  <h5>SEASON {1 + 1}</h5>
                </div>
              </div>
              <div
                className={styles.tabWrapper}
                onClick={() => {
                  setTab(0);
                }}
                style={{
                  backgroundColor: tab === 0 ? "#e3425b" : "#fff",
                  clipPath: `polygon( 72% 0, 90% 100%, 0 100%, 0 0)`,
                  marginLeft: -80,
                  zIndex: tab === 0 ? 10 : 5,
                  paddingTop: tab === 1 ? 6 : 0,
                }}
              >
                <div
                  className={
                    0 === tab
                      ? multipleClassName(styles.tab, styles.selectedTab)
                      : styles.tab
                  }
                  style={{
                    clipPath:
                      0 === tab
                        ? `polygon(77% 0, 97% 100%, 0 100%, 0 0)`
                        : "none",
                  }}
                >
                  <h5>SEASON {0 + 1}</h5>
                </div>
              </div>
            </div>
            <div
              className={styles.indianPavilion}
              style={{
                backgroundImage: `url(${values[tab]["bottomBg"]}),
                  url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/bharattech-season3/bgTopWinnersMaskV2.webp"),
                  linear-gradient(to bottom, #52079c, #1d0336)`,
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    margin: "auto",
                    height: dynamicCssSize(60, 20),
                    width: dynamicCssSize(460, 200),
                    position: "relative",
                  }}
                >
                  <CustomImageComponent
                    src={
                      "https://d3g4wmezrjkwkg.cloudfront.net/website/images/bharattech-season3/indianPavilion.webp"
                    }
                    layout="fill"
                    alt="India Pavilion"
                  />
                </div>
                <div
                  style={{
                    margin: "auto",
                    height: dynamicCssSize(100, 50),
                    width: dynamicCssSize(260, 120),
                    position: "relative",
                  }}
                >
                  <CustomImageComponent
                    src={values[tab]["seasonName"]}
                    layout="fill"
                    alt="Gamescom Latam"
                  />
                </div>
              </div>
              <div className={styles.partnerSlider}>
                <div className={styles.officialPartners}>OFFICIAL PARTNERS</div>
                <div className={styles.logosContainer}>
                  {values[tab]["partners"].map(
                    (item: IlogoImage, index: number) => {
                      return (
                        <div className={styles.bottomLogo} key={index}>
                          <CustomImageComponent
                            src={item.img}
                            layout="fill"
                            objectFit="contain"
                            alt={"official Partners logos"}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <ScrollingLogos
                logos={props.logos}
                marginTop={"30px"}
                marginBottom={"20px"}
                bgColor={"rgba(30, 9, 45, 0.4)"}
                padding={"20px 0px"}
                hideInDesktop={true}
              />
              <JuryOfTheContestComponent
                data={values[tab]["jury"]}
                source={"season3"}
              />
              <div className={styles.hightlightWrapper}>
                <div className={styles.topContainer}>OUR OUTCOME</div>
                <div className={styles.hightlightBox}>
                  <div className={styles.leftHighlight}>
                    <div className={styles.image}>
                      <CustomImageComponent
                        src={
                          "https://d3g4wmezrjkwkg.cloudfront.net/website/images/bharattech-season3/legacy_right.webp"
                        }
                        alt="left"
                        layout="fill"
                      />
                    </div>
                    <div>
                      <h4>
                        {values[tab]["seasonResult"].registration.heading}
                      </h4>
                      <p>{values[tab]["seasonResult"].registration.text}</p>
                    </div>
                  </div>
                  <div className={styles.seperator} />
                  <div className={styles.leftHighlight}>
                    <div className={styles.image}>
                      <CustomImageComponent
                        src={
                          "https://d3g4wmezrjkwkg.cloudfront.net/website/images/bharattech-season3/legacy_left.webp"
                        }
                        alt="left"
                        layout="fill"
                      />
                    </div>
                    <div>
                      <h4>{values[tab]["seasonResult"].winners.heading}</h4>
                      <p>{values[tab]["seasonResult"].winners.text}</p>
                    </div>
                  </div>
                  <div className={styles.rightHighlight}></div>
                </div>
              </div>
              <MakingTheLegacySlider
                winners={true}
                videos={values[tab]["winnerVideos"]}
              />
            </div>
          </div>
        </div>
        <div className={styles.wedgeBottom} />
      </>
    );
}

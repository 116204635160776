import React from "react";
import styles from "./Evaluation.module.css";
import parse from 'html-react-parser';
import { multipleClassName } from "../../lib/utils/helpers/helper";
import CustomImageComponent from "../common/CustomImage/CustomImageComponent";

const Evaluation = (props) => {

  const evaluationData = props;

  return (
    <div className={styles.root}>
      <div className={multipleClassName("container", styles.mainContainer)}>
        <div className={styles.waterMarkAndTextContainer}>
          <div className={styles.waterMarkNoWrap}>
            {evaluationData.watermark}
          </div>
          <div className={styles.heading}>
            {evaluationData.heading}
          </div>
          <div className={styles.subHeading}>
            {parse(evaluationData.subHeading)}
          </div>
        </div>
      </div>
      <div
        className={multipleClassName("container", styles.imageContentConatiner)}
      >
        {evaluationData.content.map((item, index) => {
          return (
            <div className={styles.imageContainer}>
              <div
                style={{ position: "relative", width: "100%", height: "40%" }}
              >
                <CustomImageComponent
                  layout="fill"
                  objectFit="contain"
                  alt={""}
                  src={item.img}
                />
              </div>
              <div style={{ marginTop: "5%" }}>
                <div className={styles.contentHeading}>{item.heading}</div>
                <div className={styles.contentPara}>{item.para}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Evaluation;

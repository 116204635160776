import {
    NextArrowV2,
    PrevArrowV2,
} from "../../common/ArrowComponent/ArrowComponentV2";
import { dynamicCssSize } from "../../../lib/utils/constants/PagesConstants";
import Slider, { Settings } from "react-slick";
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";
import { useState } from "react";
import Link from "next/link";
import Fancybox from "../../common/GDC/Fancybox";
import styles from "./IndianPavilionSlider.module.css";

export default function MakingTheLegacySlider(props: any) {
    const [selectedIndex, setSelectedIndex] = useState(1);
    const sliderSettings: Settings = {
        className: "",
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        focusOnSelect: true,
        nextArrow: <NextArrowV2 onClick />,
        prevArrow: <PrevArrowV2 onClick />,
        speed: 500,
        dotsClass: "slick-dots ",
        dots: false,
        variableWidth: true,
        autoplay: true,
        arrows: false,
        beforeChange: (old: number, index: number) => {
            setSelectedIndex(index);
        },
    };

    const sliderData = {
        videos: props.videos,
    };
    return (
        <>
            <h5
                style={{
                    width: "fit-content",
                    margin: "auto",
                    marginTop: 50,
                    fontSize: dynamicCssSize(30, 16),
                    fontWeight: 600,
                    fontStyle: "italic",
                    color: "#fff",
                }}
            >
                {"MEET OUR WINNERS"}
            </h5>
            <div style={{ marginTop: 20, width: "100%" }}>
                <Fancybox
                    options={{
                        Carousel: {
                            infinite: false,
                        },
                    }}
                >
                    <Slider {...sliderSettings}>
                        {sliderData.videos.map((item, idx) => {
                            return idx !== selectedIndex ? (
                                <div key={"sliderData.videos_" + idx}>
                                    <div
                                        className={styles.sliderContainer}
                                        key={"about_gdc_" + idx}
                                    >
                                        {/* <div className="text">
                                            <h3>{"WINNER NAME"}</h3>
                                            <p>{item.videoStudioName}</p>
                                        </div> */}
                                        <CustomImageComponent
                                            src={item.thumbnail}
                                            layout="fill"
                                            alt={"about_gdc_"}
                                        />
                                        <div className={styles.overlay} />
                                        <div className={styles.playButton}>
                                            <CustomImageComponent
                                                src={
                                                    "https://d3g4wmezrjkwkg.cloudfront.net/website/images/playButton.webp"
                                                }
                                                layout="fill"
                                                objectFit="contain"
                                                objectPosition="center"
                                                alt={"about_gdc_"}
                                            />
                                        </div>
                                    </div>

                                    {idx === selectedIndex && (
                                        <h2 className={styles.h2}>
                                            {item.title}
                                        </h2>
                                    )}
                                </div>
                            ) : (
                                <Link data-fancybox href={item.video} key={idx}>
                                    {/* <div> */}
                                    <div
                                        className={styles.sliderContainer}
                                        key={"about_gdc_" + idx}
                                    >
                                        {/* <div className="text">
                                            <h3>{"WINNER NAME"}</h3>
                                            <p>{item.videoStudioName}</p>
                                        </div> */}
                                        <CustomImageComponent
                                            src={item.thumbnail}
                                            layout="fill"
                                            alt={"about_gdc_"}
                                        />
                                        <div className={styles.overlay} />
                                        <div className={styles.playButton}>
                                            <CustomImageComponent
                                                src={
                                                    "https://d3g4wmezrjkwkg.cloudfront.net/website/images/playButton.webp"
                                                }
                                                layout="fill"
                                                objectFit="contain"
                                                objectPosition="center"
                                                alt={"about_gdc_"}
                                            />
                                        </div>
                                    </div>
                                    {idx === selectedIndex && (
                                        <h2 className={styles.h2}>
                                            {item.title}
                                        </h2>
                                    )}
                                </Link>
                            );
                        })}
                    </Slider>
                </Fancybox>
            </div>
        </>
    );
}

import React from "react";
import styles from "./Eligibility.module.css";
import { multipleClassName } from "../../lib/utils/helpers/helper";
import CustomImageComponent from "../common/CustomImage/CustomImageComponent";
import parse from 'html-react-parser';

const Eligibility = (props) => {

  const eligibilityData = props;

  return (
    <div className={styles.root}>
      <div className={styles.wedgeTop} />
      <div className={multipleClassName("container", styles.mainContainer)}>
        <div className={styles.aboutUs}>
          <div className={multipleClassName(styles.sectionCopyText)}>
            <div className={styles.waterMarkAndTextContainer}>
              <div className={styles.waterMarkNoWrap}>
                {eligibilityData.watermark}
              </div>
              <div className={styles.heading}>{eligibilityData.heading}</div>
              <div className={styles.subHeading}>
                {parse(eligibilityData.subHeading)}
              </div>
            </div>
            <div className={styles.para1}>{eligibilityData.para}</div>
          </div>
          <div style={{ zIndex: 1 }}>
            <div className={styles.card}>
              <div style={{ position: "relative" }}>
                <CustomImageComponent
                  layout="fill"
                  objectFit="contain"
                  objectPosition={"top"}
                  alt={""}
                  src={eligibilityData.card1.src}
                />
              </div>
              <div>
                <div className={styles.cardHeading}>
                  {eligibilityData.card1.heading}
                </div>
                <div className={styles.cardPara}>
                  {eligibilityData.card1.para}
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div style={{ position: "relative" }}>
                <CustomImageComponent
                  layout="fill"
                  objectFit="contain"
                  objectPosition={"top"}
                  alt={""}
                  src={eligibilityData.card2.src}
                />
              </div>
              <div>
                <div className={styles.cardHeading}>
                  {eligibilityData.card2.heading}
                </div>
                <div className={styles.cardPara}>
                  {eligibilityData.card2.para}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.wedgeBottom} />
    </div>
  );
};

export default Eligibility;

import { GetStaticProps, GetStaticPaths } from "next";
import { IOnlineGamesPageData } from "../../../../lib/types/world-war";
import {
  REVALIDATE,
  THE_TECH_TRIUMPH_URL,
  pagesWithLocale,
} from "../../../../lib/utils/constants/GlobalConstants";
import {
  getLangugageToggleData,
  getStaticPagePath,
} from "../../../../lib/utils/constants/PagesConstants";
import { getGlobalData, getNonApiData } from "../../../app/utils/get-data";
import TheTechTriumph from "../../../../Layouts/India/TheTechTriumph";

function TheTechTriumphPage(props) {
  return <TheTechTriumph {...props} />;
}

export const getStaticPaths: GetStaticPaths = async () => {
    let homePagePaths = getStaticPagePath(
      pagesWithLocale.get(THE_TECH_TRIUMPH_URL)?.locales!,
      "locale"
    );
    return {
      paths: homePagePaths,
      fallback: false,
    };
  };
  
export const getStaticProps: GetStaticProps = async (props) => {
  const locale = props.params!.locale! as string;
  const pageName: string = THE_TECH_TRIUMPH_URL;
  const languageToggleData = getLangugageToggleData(
    pagesWithLocale,
    pageName,
    locale
  );

  const {
    appVersions,
    abTestingData,
    brandModelData,
    deviceArchitectureMapping,
  } = await getNonApiData(locale);

  const data = await getGlobalData<IOnlineGamesPageData>(
    locale,
    pageName,
    pageName,
    languageToggleData,
    pageName
  );

  return {
    props: {
      ...data,
      appVersions,
      brandModelData,
      abTestingData,
      deviceArchitectureMapping,
    },
    revalidate: REVALIDATE,
  };
};

export default TheTechTriumphPage;

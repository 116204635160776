"use client"
import { multipleClassName } from "../../../lib/utils/helpers/helper";
import CustomImageComponent from "../CustomImage/CustomImageComponent";
import styles from "./ScrollingLogos.module.css"
import useIsMobile from "../../../lib/customHooks/isMobile";
import { dynamicCssSize } from "../../../lib/utils/constants/PagesConstants";

export default function ScrollingLogos(props){
  const awardData = props.logos;
    const {isMobileDevice} = useIsMobile();
  const hideInMobile = props.hide || false
return (
  <>
      <style jsx>{`
          .slide-track {
              animation: scroll 20s linear infinite;
              display: flex;
              width: calc(260px * ${2 * awardData.length});
              animation-play-state: paused;
          }

          .slide {
              padding: 0px 10px;
              transition: all 0.5s ease;
          }

          .slide:hover {
              transform: scale(110%);
              opacity: 1;
          }

          .imageContainer {
              position: relative;
              width: ${dynamicCssSize(140,110)};
              height: ${dynamicCssSize(50,40)};
          }
          @media screen and (max-width: 576px) {
            
              .awardComponentContainer {
                  width:100%;
                  display:${hideInMobile?"none":"block"};
                  margin-top:${props.marginTop || "100px"};
                  margin-bottom:${props.marginBottom || "0"};
                  padding:${props.padding || "auto"};
                  background-color: ${props.bgColor || "none"};
              }
              h3{
                font-style:italic;
              }
              @keyframes scroll {
                  0% {
                      transform: translateX(0);
                  }
                  100% {
                      transform: translateX(
                          calc(-160px * ${awardData.length})
                      );
                  }
              }

              .slider {
              }

              .slide-track {
                  width: calc(160px * ${2 * awardData.length});
                  animation-play-state: running;
              }

              .slide {
                  padding: 0px 0px;
              }

              .imageContainer {
                  width: 100px;
              }
              .imageDesc {
                  font-size: 8px;
                  padding-top: 10px;
              }
          }
      `}</style>
      <div className={multipleClassName("awardComponentContainer", styles.awardComponentContainer)} style={{display:hideInMobile || !isMobileDevice ? "none":"block"}}>
          <div className={multipleClassName("container", styles.headingContainer)}>
              <h3 className={styles.h3}>OFFICIAL PARTNERS</h3>
          </div>
          <div className={styles.slider}>
              <div className="slide-track">
                  {awardData.map((item, idx) => {
                      return (
                          <div className={styles.slide} key={idx}>
                              <div className={styles.imageContainer}>
                                  <CustomImageComponent
                                      src={item.img}
                                      layout="fill"
                                      objectFit="contain"
                                      alt={"award Data"}
                                  />
                              </div>
                          </div>
                      );
                  })}
                  {awardData.map((item, idx) => {
                      return (
                          <div className={styles.slide} key={idx}>
                              <div className={styles.imageContainer}>
                                  <CustomImageComponent
                                      src={item.img}
                                      layout="fill"
                                      objectFit="contain"
                                      alt={"award Data"}
                                  />
                              </div>
                          </div>
                      );
                  })}
                  {awardData.map((item, idx) => {
                      return (
                          <div className={styles.slide} key={idx}>
                              <div className={styles.imageContainer}>
                                  <CustomImageComponent
                                      src={item.img}
                                      layout="fill"
                                      objectFit="contain"
                                      alt={"award Data"}
                                  />
                              </div>
                          </div>
                      );
                  })}
              </div>
          </div>
      </div>
  </>
);
}
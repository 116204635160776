import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";
import useIsMobile from "../../../lib/customHooks/isMobile";
import styles from "./JuryOfTheContestS3.module.css";
import { multipleClassName } from "../../../lib/utils/helpers/helper";
export default function JuryOfTheContestComponent(props: any) {
    const data = props.data;
    const { isMobileDevice } = useIsMobile();
    return (
        <>
            <div className={styles.mainContainer}>
                <div
                    className={multipleClassName(
                        "container-fluid",
                        styles.jurySection
                    )}
                >
                    <div className={styles.headingParent}>
                        <h2 className={styles.heading}>{data.heading}</h2>
                    </div>
                    <div
                        className={
                            isMobileDevice
                                ? multipleClassName("row", styles.juryContainer)
                                : styles.juryContainer
                        }
                    >
                        {[...data.juryList].map((item, i) => {
                            return (
                                <div
                                    className={multipleClassName(
                                        "col-6",
                                        "col-sm-6",
                                        "col-lg-2",
                                        styles.juryBox
                                    )}
                                    key={"jury_contest_" + i}
                                >
                                    <div
                                        className={styles.itemContainer}
                                        key={"jury_contest_" + i}
                                    >
                                        <div
                                            className={styles.contentContainer}
                                        >
                                            <div>
                                                <div
                                                    className={
                                                        styles.contentStyle
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.imageStyleContainer
                                                        }
                                                        style={{
                                                            height: "clamp(120px, 10.98vw, 211px)",
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                styles.imageContainer
                                                            }
                                                        >
                                                            <CustomImageComponent
                                                                src={item.img}
                                                                layout="fill"
                                                                objectFit={
                                                                    "cover"
                                                                }
                                                                alt={
                                                                    "jury image"
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3
                                                    className={styles.h3}
                                                    style={{
                                                        fontSize:
                                                            "clamp(12px, 1.14vw, 22px)",
                                                    }}
                                                >
                                                    {item.name}
                                                </h3>
                                                <h5 className={styles.h5}>
                                                    {item.designation}
                                                </h5>
                                            </div>
                                        </div>
                                        <div className={styles.box2}></div>
                                        <svg
                                            className={styles.flt_svg}
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <defs>
                                                <filter id="flt_tag">
                                                    <feGaussianBlur
                                                        in="SourceGraphic"
                                                        stdDeviation="4"
                                                        result="blur"
                                                    />
                                                    <feColorMatrix
                                                        in="blur"
                                                        mode="matrix"
                                                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                                                        result="flt_tag"
                                                    />
                                                    <feComposite
                                                        in="SourceGraphic"
                                                        in2="flt_tag"
                                                        operator="atop"
                                                    />
                                                </filter>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

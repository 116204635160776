import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./Marquee.module.css";

const Marquee = ({ children }: { children: React.ReactNode }) => {
  const [isMounted, setIsMounted] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const marqueeRef = useRef<HTMLDivElement>(null);
  const [multiplier, setMultiplier] = useState(1);
  const [duration, setDuration] = useState(0);

  const calculateMultiplier = useCallback(() => {
    if (!marqueeRef.current || !containerRef.current) return;
    const containerRect = containerRef.current.getBoundingClientRect();
    const marqueeRect = marqueeRef.current.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const marqueeWidth = marqueeRect.width;

    if (marqueeWidth < containerWidth) {
      setMultiplier(Math.ceil(containerWidth / marqueeWidth));
    } else {
      setMultiplier(1);
    }

    const speedFactor = 15;
    const newDuration = speedFactor;
    setDuration(newDuration);
  }, []);

  const multiplyChildren = useCallback(
    (multiplier: number) => {
      const arraySize = multiplier >= 0 ? multiplier : 0;
      return [...Array(arraySize)].map((_, i) => (
        <Fragment key={i}>{children}</Fragment>
      ));
    },
    [children]
  );

  useEffect(() => {
    if (!isMounted) return;
    calculateMultiplier();
    if (marqueeRef.current && containerRef.current) {
      const resizeObserver = new ResizeObserver(() => calculateMultiplier());
      resizeObserver.observe(marqueeRef.current);
      resizeObserver.observe(containerRef.current);
      return () => {
        if (!resizeObserver) return;
        resizeObserver.disconnect();
      };
    }
  }, [calculateMultiplier, containerRef, isMounted]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    calculateMultiplier();
  }, [children, calculateMultiplier]);

  if (!isMounted) return null;

  return (
    <div className={styles.container} ref={containerRef}>
      <div
        className={styles.animationContainer}
        style={{ animationDuration: `${duration}s` }}
      >
        <div ref={marqueeRef} className={styles.initialMarquee}>
          {children}
        </div>
        {multiplyChildren(multiplier - 1)}
      </div>
      <div
        className={styles.animationContainer}
        style={{ animationDuration: `${duration}s` }}
      >
        {multiplyChildren(multiplier)}
      </div>
    </div>
  );
};

export default Marquee;

import React from "react";
import styles from "./Timelines.module.css";
import { multipleClassName } from "../../lib/utils/helpers/helper";
import CustomImageComponent from "../common/CustomImage/CustomImageComponent";
import parse from 'html-react-parser';
import GDCSubmitButton from "../common/FixedDownloadButton/GDCSubmitButton";
import { getGDCPageEvents } from "../../lib/analytics/AnalyticsEvents";
import Analytics from "../../lib/analytics/Analytics";

const Timelines = (props) => {

  const timelineData = props;
  const sendAnalyticsGDCHedaer = () => {
    Analytics.getInstance().logEvent(getGDCPageEvents('Header_Submit_Clicked'));
  }

  return (
    <div className={styles.root}>
      <div className={styles.wedgeTop} />
      <div className={multipleClassName("container", styles.mainContainer)}>
        <div className={styles.aboutUs}>
          <div className={multipleClassName(styles.sectionCopyText)}>
            <div className={styles.waterMarkAndTextContainer}>
              <div className={styles.waterMarkNoWrap}>
                {timelineData.watermark}
              </div>
              <div className={styles.heading}>{timelineData.heading}</div>
              <div className={styles.subHeading}>
                {parse(timelineData.subHeading)}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.contentDesktop}>
          <div className={styles.contentsContainer}>
            {timelineData.content.map((item, index) => {
              return (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "50%",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                      }}
                    >
                      <CustomImageComponent
                        layout="fill"
                        objectFit="contain"
                        alt={""}
                        src={item.img}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: "5%" }}>
                    <div className={styles.contentHeading}>
                      {index + 1 + ". "}
                      {item.heading}
                    </div>
                    <div className={styles.contentPara}>{item.para}</div>
                    <div className={styles.contentDate}>{item.date}</div>
                  </div>
                  {index !== 3 && (
                    <div
                      style={{
                        position: "absolute",
                        right: "calc(clamp(20px, 2.3vw, 50px) * -1)",
                        bottom: 30,
                        width: "30%",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                          opacity: 0.3,
                        }}
                      >
                        <CustomImageComponent
                          layout="fill"
                          objectFit="contain"
                          alt={""}
                          src={
                            "https://d3g4wmezrjkwkg.cloudfront.net/website/images/ttt-revamp/timelines-arrow.webp"
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.contentMobile}>
          <div className={styles.contentsContainer}>
            {timelineData.content.map((item, index) => {
              return (
                <div className={styles.contentMobile}>
                  <div
                    style={{
                      position: "relative",
                      justifyContent: "space-evenly",
                      height: "clamp(105px, 10.04vw, 200px)",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "30%",
                        height: "80%",
                        marginRight: "5%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <CustomImageComponent
                          layout="fill"
                          objectFit="contain"
                          objectPosition={"left"}
                          alt={""}
                          src={item.img}
                        />
                      </div>
                    </div>
                    <div>
                      <div className={styles.contentHeading}>
                        {index + 1 + ". "}
                        {item.heading}
                      </div>
                      <div className={styles.contentPara}>{item.para}</div>
                      <div className={styles.contentDate}>{item.date}</div>
                    </div>
                  </div>
                  <div>
                    {index !== 3 && (
                      <div
                        style={{
                          right: "calc(clamp(20px, 10.85vw, 200px) * -1)",
                          bottom: 30,
                          width: "100%",
                          height: "60px",
                          paddingLeft: 7,
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: "40%",
                            height: "20%",
                            opacity: 0.3,
                            transform: "rotate(90deg)",
                          }}
                        >
                          <CustomImageComponent
                            layout="fill"
                            objectFit="contain"
                            objectPosition={"bottom"}
                            alt={""}
                            src={
                              "https://d3g4wmezrjkwkg.cloudfront.net/website/images/ttt-revamp/timelines-arrow.webp"
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.bannerButton}>
          <GDCSubmitButton
            text={timelineData.buttonData.text}
            url={timelineData.buttonData.url}
            sendAnalytics={sendAnalyticsGDCHedaer}
          />
        </div>
      </div>
      <div className={styles.wedgeBottom} />
    </div>
  );
};

export default Timelines;